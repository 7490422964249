<template>

    <div >
        <div class="topBanner1">

            <div class="countryStyle">
            {{student_school}}
            </div>

            <div class="nameStyle">
            {{student_name}}
            </div>

        </div>

        <div style="margin-left: 150px;margin-top: 50px;margin-right: 150px;">


            <!-- <div class="session-title" @click="toTranscript">查看电子成绩单</div> -->
            <div class="session-title1" @click="exportAward" style="width:200px;">Download Certificate</div>
            <!-- <div class="session-title1" @click="exportAward" style="width:200px;">下载成绩单和证书</div> -->
            <div style="clear:both"></div>

            <div class="my-events">
                <div v-for="(item,index) in rankList" :key="index">

                    <!-- <div class="numLab">{{ 'Total Socores： ' + item.total_score +'/'+ item.full_score }}</div> -->
                    <!-- <div class="numLab">{{ 'Total Socores： ' + item.total_score +'/'+ item.full_score +   ' &nbsp;&nbsp;&nbsp;&nbsp;        Rank： ' +  item.percentile}}</div> -->
                    <div class="events-item">
                        <div style="position: absolute;">
                            <!-- <img src="../../assets/awardListBg.png" style="width: 100%;height: 110px;"> -->
    <!--                         
                            <div class="placeLable" style="margin-top:30px; ">
                                <div v-if="item.award_info.award_status== 'no ready'">奖项还未公布</div>
                                <div v-if="item.award_info.award_status== 'no award'">请再接再厉！</div>
                                <div style="cursor:pointer" v-if="item.award_info.award_status== 'success'" @click="toCertificate(item)">{{item.award_info.award.award_show_lable}}</div>

                            </div> -->

                            
                            <!-- <div @click="toCertificate(item)"  v-if="item.award_info.award_status== 'success'" class="showLable1">
                                查看证书
                            </div> -->
                        </div>

                        <div @click="toCertificate(item)" class="showLable">
                                View Certificates
                            </div>

                        <div style="clear:both"></div>

                        
                        
                        <div style="padding: 0px 36px ;position:relative;">
                            <div class="event_name">{{item.application_event}}</div>
                            <div class="scoreLable">Score： <span style="color:#D9661F"> {{item.total_score }}</span>  / {{item.full_score}}</div>

                            

                        <!-- <div style="position: relative;margin-top: 10px;height: 60px;display: flex;flex-direction: row">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                            <div style="color: #FFB041;font-size: 18px;font-weight: 600">开始时间</div>
                            <div style="color: #FD5900;font-size: 18px;font-weight: 600;margin-left: 10px">
                                {{ 1111}}
                            </div>
                            </div>
                            <div
                                style="background-color: red;top: 50%;transform: translateY(-50%);display: flex;flex-direction: row;align-items: center;position: absolute;right: 0">
                                <div class="event-status">状态</div>
                                <div class="event-entry" style="margin-left: 10px" @click="goExam(item)">进入考场</div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>




        
    </div>

</template>

<script>
import {getGroupAwards,getAwards,getMyAward,findMyTranscript,checkTranscriptOpen,getTeamMembers} from '../../api/index'
import {getUserId,getProjectCode} from '../../utils/store'
import '../../assets/common/font.css'

export default {
    data(){
        return{
            events:[
                'Artithon - Academic Marathon in Art',
                'Biothon - Academic Marathon in Biology',
                'Chemithon - Academic Marathon in Chemistry',
                'Econthon - Academic Marathon in Economics',
                'Geothon - Academic Marathon in Geography',
                'Historithon - Academic Marathon in History',
                'Mathethon - Academic Marathon in Mathematics',
                'Physithon - Academic Marathon in Physics',
                'Psychothon - Academic Marathon in Psychology'
            ],
            currentEvent:'Artithon - Academic Marathon in Art',
            groupAwards:[],
            personalAwards:[],
            myAward:{
            },
            show:false,
            showGroup:false,
            showCom:false,
            rankList:[],
            student_name: '',
            student_school: '',
        }
    },
    mounted(){
        // this.getGroupAwards()
        // this.getAwards()
        // this.getMyAward()
        this.findMyTranscript()
        this.getTeamMembers()
    },
    methods:{

        findMyTranscript(){
            findMyTranscript(getUserId(),getProjectCode()).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.rankList = res.data.data.score_info
                }
            })
        },
        getTeamMembers(){
            getTeamMembers(getProjectCode(),getUserId()).then((res) => {
                var result = res.data.data

                console.log(1111,result)

                this.student_name = result.team_names.join(' ')
                this.student_school = result.country
                
            })
        },
        handleEventName(event){
            if (event == undefined){
                return ''
            }
            let event_array = event.split(" ")
            console.log(111,event,event_array)
            return event_array[0]
        },

        showDetail(item){
            this.$router.push({
                path:'/awardDetail',
                query:{
                    awardId:'',
                    data:item
                }
            })
        },

        exportAward(){
            checkTranscriptOpen(getProjectCode()).then(res=>{
                if(res.data){
                    this.$router.push({
                        path:'/awardExport'
                    })
                }else{
                    this.$message.error('E-transcripts are not yet available, please check back after you receive the result notification email')
                }
            })
        },

        
        getAwards(){
            getAwards('21-22FBLAbiz_china','Business Communication').then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.personalAwards = res.data.data
                }
            })
        },
        getMyAward(){
            getMyAward('6158c9dba4640b2c518b4573').then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.myAward = res.data.data
                }
            })
        },
        getGroupAwards(){
            getGroupAwards('21-22FBLAbiz_china','Business Communication').then(res=>{
                if(res.data.code===0){
                    this.groupAwards = res.data.data
                }
            })
        },
        chooseEvent(item){
            this.currentEvent = item
            this.getGroupAwards()
            this.getAwards()
        },
        upfirstName(name){

             return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function(word) {
                //return word.slice(0, 1).toUpperCase() + word.slice(1);
                return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
            });
            
            let transferName = name.toLowerCase();
            return transferName.charAt(0).toUpperCase() + transferName.slice(1)
        },
        rank(number){
            if(number===1){
                return 'st';
            }else if(number===2){
                return 'nd';
            }else if(number===3){
                return 'rd';
            }else{
                return 'th'
            }
        },
        printPersonal(){
            let url = this.$router.resolve({
                path:'/blank'
            })
            let newWindow=window.open(url.href,"_blank");//打印窗口要换成页面的url
            let obj = document.getElementById('cerP')

            let docStr = obj.innerHTML;
            
            newWindow.document.write(docStr);
            
            newWindow.document.close();
            
            newWindow.print();
            
            newWindow.close();
            
        },
        toTranscript(){
            checkTranscriptOpen(getProjectCode()).then(res=>{
                if(res.data){
                    this.$router.push({
                        path:'/transcript'
                    })
                }else{
                    this.$message.error('E-transcripts are not yet available, please check back after you receive the result notification email')
                }
            })
        },
        toCertificate(data){
            if(data && data.award_info && data.award_info.award_status==='success'){
                this.$router.push({
                    path:'/certificate',
                    query:{
                        score_id:data._id.$id
                    }
                })
            }
        }
    }
}
</script>

<style lang="less">
.el-dialog{
    width:320mm;
}
.my-events {
  width: 100%;
  margin-top: 15px;
  margin-right: 45px;
}
.numLab{
    font-size: 18px;
    color: white;
    font-family: Montserrat-SemiBold;
}

.placeLable{
    position: absolute;
    right: 10px;
    width: 170px;
    height: 60px;
    color: white;
    z-index: 3;
    // background-color: red;
    top: 35%;transform: translateY(-50%);display: flex;flex-direction: row;
    font-family: Montserrat-SemiBold;
}
.showLable{
    position: absolute;
    right: 120px;
    z-index: 3;
    width: 140px;
    height: 26px;
    color: white;
    font-size: 15px;
    border-radius: 13px;
    background-color: #003262;
    top: 78%;transform: translateY(-50%);flex-direction: row;
    text-align: center;
    line-height: 26px;
    align-content: center;
    font-family: Montserrat-SemiBold;
}

.showLable1{
 position: absolute;
    right: 20px;
    z-index: 3;
    width: 80px;
    height: 20px;
    color: #003262;
    font-size: 15px;
    border-radius: 10px;
    background-color: white;
    top: 78%;transform: translateY(-50%);flex-direction: row;
    text-align: center;
    line-height: 20px;
    align-content: center;
    font-family: Montserrat-SemiBold;
}
.event_name {
  font-size: 34px;
  margin-top: 0px;
  font-weight: 600;
  color: #003262;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Montserrat-SemiBold;
}
.scoreLable{
    font-size: 24px;
    margin-top: 0px;
    font-weight: 600;
    width: 300px;
    color: #003262;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Montserrat-SemiBold;
}
.events-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 110px;
  margin-right: 45px;
  margin-bottom: 30px;
  margin-top: 15px;
  font-family: Montserrat-SemiBold;
  background-color: #F9F9F9;
}

.my-events {
  width: 100%;
  margin-top: 35px;
  margin-right: 45px;
}

.session-title {
  float: left;
//   font-weight: 600;
  color: #003262;
  border: 1px solid #003262;
  font-size: 18px;
  align-content: center;
  text-align:center;
  width: 150px;
  height: 35px;
//   border-radius: 20px;
//   background-color: white;
  line-height:35px;
  font-family: Montserrat-SemiBold;
  cursor: pointer;

}

.session-title1 {
  float: left;
  margin-left: 15px;
//   font-weight: 600;
  color: white;
  font-size: 18px;
  align-content: center;
  text-align:center;
  width: 150px;
  height: 36px;
//   border-radius: 20px;
  background-color: #003262;
  line-height:36px;
  font-family: Montserrat-SemiBold;
  cursor: pointer;

}
.topBanner1 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #D9661F;
  background-size: cover;
}
.countryStyle{
  position: absolute;
  margin-top: -20px;
  margin-left: 150px;
  font-size: 20px;
  color: white;
  height: 25px;
  font-family: Montserrat-SemiBold;
}

.nameStyle{
  position: absolute;
  margin-top: 25px;
  margin-left: 150px;
  font-size: 13px;
  color: white;
  height: 25px;
  font-family: Montserrat-SemiBold;
}
</style>